<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>{{ this.$t('user_group.users') }}</li>
            <li>{{ this.$t('general.inactive_users_groups') }}
            </li>
          </ul>
        </nav>
      </div>
      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left"><span> {{ this.$t('general.inactive_users_groups') }}</span></h2>
        </div>
        <div class="section-header-right">
        </div>
      </div>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color:green;">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>
      <div class="card">

        <div class="card-header">
          <ul id="notifications" class="uk-tab page-tab" uk-switcher="animation: uk-animation-fade">
            <li class="uk-active"><a href="#" aria-expanded="true">
                <h4><i class="uil-user"></i>{{ this.$t('general.inactive_users') }}</h4>
              </a></li>
            <li><a href="#" aria-expanded="false">
                <h4><i class="uil-users-alt "></i>{{ this.$t('general.inactive_users_groups') }}</h4>
              </a></li>
          </ul>

          <div class="uk-switcher" style="touch-action: pan-y pinch-zoom;">
            <li class="uk-active">
              <div class="card-header actions-toolbar border-0 pl-0">
                <div class="d-flex justify-content-between align-items-center">
                    <form class="uk-search uk-search-navbar">
                      <i class="uil-search position-absolute"></i>
                      <input class="uk-search-input shadow-0 uk-form-small" v-model="user_data.filter.search"
                        type="search" :placeholder="$t('general.first_name_and_last_name_or_id_no')" autofocus="">
                    </form>
                  <div class="d-flex">
                    <!-- Shorting -->

                    <a href="javascript:void(0)" class="btn btn-icon btn-hover  btn-circle"
                      :uk-tooltip="$t('filters.order.sort')" title="" aria-expanded="false">
                      <i class="uil-list-ui-alt"></i>
                    </a>
                    <div uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up"
                      class="uk-dropdown">
                      <ul class="uk-nav uk-dropdown-nav">
                        <li class="uk-active"><a href="javascript:void(0)" @click="sortOrder('asc', 'name')">{{
                          $t('sorting.a_z') }}</a></li>
                        <li><a href="javascript:void(0)" @click="sortOrder('desc', 'name')">{{ $t('sorting.z_a') }}</a>
                        </li>
                        <li><a href="javascript:void(0)" @click="sortOrder('asc', 'id')"> {{
                          $t('sorting.created_at_old')
                        }}</a></li>
                        <li><a href="javascript:void(0)" @click="sortOrder('desc', 'id')">
                            {{ $t('sorting.created_at_new') }}</a></li>
                      </ul>
                    </div>

                    <!-- Filter -->
                    <a href="javascript:void(0)" class="btn btn-icon btn-hover  btn-circle"
                      :uk-tooltip="$t('general.filter_list')">
                      <i class="uil-filter"></i>
                    </a>
                    <UserFilter @getFilter="getFilter($event)"></UserFilter>

                    <!-- Export -->
                    <a href="javascript:void(0)" @click="exportList(0)" class="btn btn-icon btn-hover  btn-circle"
                      :uk-tooltip="$t('filters.export_excell')" title="" aria-expanded="false">
                      <i class="fa-file-excel fa"></i>
                    </a>

                  </div>
                </div>
              </div>

              <!-- Table -->

              <default-loading v-if="user_loading"></default-loading>
              <template v-else>
                <div class="table-responsive">
                  <table class="table align-items-center">
                    <thead>
                    <tr>
                      <th scope="col">{{this.$t('general.first_name_and_last_name')}}
</th>
                      <th scope="col">{{this.$t('user_group.list.user_title')}}
</th>
                      <th scope="col">{{this.$t('users.last_login_date')}}</th>
                      <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody class="list">
                      <!--user item-->
                      <tr v-for="(item, index) in formattedItem" :key="index">
                        <th scope="row">
                          <div class="media align-items-center">
                            <div>
                              <div class="avatar-parent-child" style="width: max-content">
                                <img alt="Image placeholder" :src="item.photo_link" class="avatar  rounded-circle">
                                <span class="avatar-child avatar-badge bg-success"></span>
                              </div>
                            </div>
                            <div class="media-body ml-4">
                              <a href="javascript:void(0)" class="name h6 mb-0 text-sm">{{ item.fullname }}</a>
                              <small class="d-block font-weight-bold">#{{ item.id }}</small>
                            </div>
                          </div>
                        </th>
                        <td>{{ item.title }}</td>
                        <td> {{ item.last_login_at }}</td>
                        <td class="text-right">
                          <!-- Actions -->
                          <div class="actions ml-3">
                            <a href="#" class="btn btn-icon btn-hover btn-sm btn-circle"
                              @click="userUpdateStatus(item.id)" uk-tooltip="Aktif Yap" title="" aria-expanded="false">
                              <i class="uil-plus-circle text-success"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <pagination class="mb-3 mt-2" v-if="user_data.pagination && user_data.pagination.hasOwnProperty('links')"
                  :pagination="user_data.pagination" @changePage="setPage($event)"></pagination>
              </template>
            </li>
            <li>
              <div class="card-header actions-toolbar border-0 pl-0">
                <div class="d-flex justify-content-between align-items-center">
                  
                    <form class="uk-search uk-search-navbar">
                      <i class="uil-search position-absolute"></i>
                      <input class="uk-search-input shadow-0 uk-form-small" v-model="group.filter.search" type="search"
                        placeholder="Grup Adı" autofocus="">
                    </form>

                  <div class="d-flex">
                    <!-- Shorting -->
                    <a href="javascript:void(0)" class="btn btn-icon btn-hover  btn-circle"
                      :uk-tooltip="$t('filters.order.sort')" title="" aria-expanded="false">
                      <i class="uil-list-ui-alt"></i>
                    </a>
                    <div uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up"
                      class="uk-dropdown">
                      <ul class="uk-nav uk-dropdown-nav">
                        <li class="uk-active"><a href="javascript:void(0)" @click="sortOrder('asc', 'name', false)">{{
                          $t('sorting.a_z') }}</a></li>
                        <li><a href="javascript:void(0)" @click="sortOrder('desc', 'name', false)">{{
                          $t('sorting.z_a')
                        }}</a></li>
                        <li><a href="javascript:void(0)" @click="sortOrder('asc', 'id', false)">
                            {{ $t('sorting.created_at_old') }}</a></li>
                        <li><a href="javascript:void(0)" @click="sortOrder('desc', 'id', false)">
                            {{ $t('sorting.created_at_new') }}</a></li>
                      </ul>
                    </div>
                    <!-- Export -->
                    <a href="javascript:void(0)" @click="exportList(0, groupExportURL, 'Gruplar')"
                      class="btn btn-icon btn-hover  btn-circle" :uk-tooltip="$t('filters.export_excell')" title=""
                      aria-expanded="false">
                      <i class="fa-file-excel fa"></i>
                    </a>
                  </div>
                </div>
              </div>
              <!-- Table -->
              <default-loading v-if="group_loading"></default-loading>
              <template v-else>
                <div class="table-responsive">
                  <table class="table align-items-center">
                    <thead>
                      <tr>
                        <th scope="col">Grup Adı</th>
                        <th scope="col">Kullanıcı Sayısı</th>
                        <th scope="col">Grup Oluşturma Tarihi</th>
                        <th scope="col">Durum</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody class="list">
                      <!--group item-->
                      <tr v-for="item in formattedGroupItem">
                        <th scope="row">
                          <div class="media align-items-center">
                            <div>
                              <div class="avatar-parent-child" style="width: max-content">
                                <i class="icon-feather-users"></i>
                                <span class="avatar-child avatar-badge bg-success"></span>
                              </div>
                            </div>
                            <div class="media-body ml-4">
                              <a href="javascript:void(0)" class="name h6 mb-0 text-sm">{{ item.name }}</a>
                            </div>
                          </div>
                        </th>
                        <td><i class="uil-user"></i> {{ item.count }}</td>
                        <td><i class="uil-calendar-alt"></i> {{ item.created_at }}</td>
                        <td><i class="uil-check"></i> {{ item.status }}</td>
                        <td class="text-right">
                          <!-- Actions -->
                          <div class="actions ml-3">
                            <a href="#" class="btn btn-icon btn-hover btn-sm btn-circle"
                              @click="groupUpdateStatus(item.id)" uk-tooltip="Aktif Yap" title="" aria-expanded="false">
                              <i class="uil-plus-circle text-success"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <pagination v-if="group.pagination && group.pagination.hasOwnProperty('links')"
                  :pagination="group.pagination" @changePage="setPage($event, false)"></pagination>
              </template>
            </li>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import UserFilter from "@/view/company/User/Filter";
import store from "@/core/services";
import moment from "moment";
import axios from "axios";
import Pagination from "@/assets/components/Pagination";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import module, {
  BASE_URL,
  GET_ITEMS,
  MODULE_NAME,
  ERROR,
  ITEMS,
  UPDATE_USER,
  LOADING,
  SUCCESS as USER_SUCCES
} from "@/core/services/store/user.module";

import group_module, {
  BASE_URL as GROUP_BASE_URL,
  MODULE_NAME as GROUP_MODULE_NAME,
  ERROR as GROUP_ERROR,
  ITEMS as GROUP_ITEMS,
  CREATE_ITEM as GROUP_UPDATE_ITEM,
  LOADING as GROUP_LOADING,
  SUCCESS as GROUP_SUCCESS
} from "@/core/services/store/user_group.module";

export default {
  name: "Users",
  components: { UserFilter, Pagination, DefaultLoading },
  data() {
    return {
      successMessage: null,
      groupExportURL: '/api/group/export',
      filters: {},
      filter: {
        search: null,
        order: "desc",
        sort: "id",
        company_id: null,
      },
      user_data: {
        pagination: null,
        activeItem: null,
        page: 1,
        per_page: 10,
        filter: {
          search: null,
          order: "desc",
          sort: "id",
          order_by: "id",
        },
      },
      group: {
        pagination: null,
        activeItem: null,
        page: 1,
        per_page: 10,
        filter: {
          search: null,
          order_by: "id",
          order: "desc",
          sort: "desc",
        },
      },
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(MODULE_NAME, module);
    registerStoreModule(GROUP_MODULE_NAME, group_module);
  },
  computed: {
    items: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) {
      },
    },
    user_loading: {
      get() {
        return store.getters[MODULE_NAME + "/" + LOADING];
      },
      set(value) {
      },
    },
    group_loading: {
      get() {
        return store.getters[GROUP_MODULE_NAME + "/" + GROUP_LOADING];
      },
      set(value) {
      },
    },
    group_success() {
      return store.getters[GROUP_MODULE_NAME + "/" + GROUP_SUCCESS];
    },
    user_success() {
      return store.getters[MODULE_NAME + "/" + USER_SUCCES];
    },
    groups: {
      get() {
        return store.getters[GROUP_MODULE_NAME + "/" + GROUP_ITEMS];
      },
      set(value) {
      },
    },
    errors: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) {
      },
    },
    formattedItem() {
      let results = [];
      if (this.items === null || !("data" in this.items)) return [];
      this.user_data.pagination = this.items;

      this.items.data.forEach((currentItem) => {
        results.push({
          id: currentItem.id,
          photo_link: currentItem.photo_link,
          fullname: [currentItem.name, currentItem.surname].join(" "),
          title: currentItem.title ? currentItem.title.name : "-",
          created_at: currentItem.created_at
            ? moment(currentItem.created_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
          last_login_at: currentItem.last_login_at
            ? moment(currentItem.last_login_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
        });
      });

      return results;
    },
    formattedGroupItem() {
      let results = [];
      if (this.groups === null || !("data" in this.groups)) return [];

      for (let i = 0; i < this.groups.data.length; i++) {
        let currentItem = this.groups.data[i];
        this.group.pagination = this.groups;
        if (currentItem) {
          results.push({
            id: currentItem.id,
            name: currentItem.name,
            status: currentItem.status
              ? this.$t("general.active")
              : this.$t("general.passive"),
            count: currentItem.count,
            created_at: currentItem.created_at
              ? moment(currentItem.created_at).format("DD/MM/YYYY HH:mm:ss")
              : "-",
          });
        }
      }
      return results;
    },
  },
  methods: {
    filterResult(is_user = true, filter = null, page = 1) {
      let $this = this;
      let self = is_user ? this.user_data : this.group;
      let M_NAME = is_user ? MODULE_NAME : GROUP_MODULE_NAME;
      let B_URL = is_user ? BASE_URL : GROUP_BASE_URL;
      let filters = {
        like: self.filter.search,
        page: page,
        per_page: self.per_page,
        sort: self.filter.sort,
        order: self.filter.order,
        order_by: self.filter.order_by,
        status_id: 0,
      }

      if (!is_user)
        filters.sort = filters.order


      if (filter) {
        $.each(filter, function (key, value) {
          if (value) {
            $this.filters[key] = value;
            filters[key] = value;
          }
        });
      }
      this.$store.dispatch(M_NAME + "/" + GET_ITEMS, {
        url: B_URL,
        filters: filters
      });
      //UIkit.dropdown('#filterDropdown').hide();
    },
    userUpdateStatus(id) {
      store.dispatch(MODULE_NAME + '/' + UPDATE_USER, {
        url: BASE_URL + '/status/' + id,
        contents: {
          user: id,
          status_id: 0
        },
        returnType: "stateless",
        acceptPromise: true,
        _method: "put"
      })
    },
    groupUpdateStatus(id) {
      this.$store.dispatch(GROUP_MODULE_NAME + "/" + GROUP_UPDATE_ITEM, {
        url: GROUP_BASE_URL + "/" + id + "/status",
        contents: {
          id: id,
          status_id: 1,
        },
        returnType: "stateless",
        acceptPromise: true,
      })

    },
    getFilter(value) {
      this.filter.company_id = value.company_id;

      this.filterResult(true, value);
    },
    onClickEdit(id) {
      if (id !== this.parentMemberId) {
        let routeData = this.$router.resolve({
          name: "EditUser",
          params: { id: id },
        });
        window.open(routeData.href, "_blank");
      }
    },
    sortOrder(order, sort, is_user = true) {
      let self = is_user ? this.user_data : this.group;
      self.page = 1;
      self.filter.order = order;
      self.filter.order_by = sort;
      self.filter.sort = sort;

      this.filterResult(is_user);
    },
    setPage(page, is_user = true) {
      let self = is_user ? this.user_data : this.group;
      self.page = page;
      this.filterResult(is_user, this.filter.company_id ? { company_id: this.filter.company_id } : null, page);
    },
    resetMessages() {
      this.successMessage = null
    },
    exportList(status_id = null, url = '/api/user/export', file_name = 'passive_users_list_') {
      let self = this;
      let params = []
      if (status_id != null)
        params = {
          'status_id': status_id,
          ...this.filters
        };

      let config = {
        responseType: 'blob',
        headers: this.apiHeaders,
      };
      axios.post(process.env.VUE_APP_BACKEND_APP_URL + url, params, config)
        .then((response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement('a');

          fileLink.href = fileURL;
          let day = moment().format('YYYYMMDDHHMMSS');
          let fileName = file_name + day + '.xlsx';
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        }).catch((error) => {
        })
    },
  },
  mounted() {
    this.filterResult();
    this.filterResult(false);
  },
  watch: {
    user_success: function (val) {
      if (val) {
        this.successMessage = this.$t('general.successfully_updated')
        this.filterResult();
      }
    },
    group_success: function (val) {
      if (val) {
        this.successMessage = this.$t('general.successfully_updated')
        this.filterResult(false)
      }
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    'user_data.filter.search'() {
      this.debouncedFilterResult(true);
    },
    'group.filter.search'() {
      this.debouncedFilterResult(false);
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 1500);
    this.debouncedFilterResult = _.debounce(this.filterResult, 500);
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>
